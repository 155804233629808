<template>
    <div class="sub-nav">
        <div class="container sub-nav-box text-white">
            <div class="contact-info">
                <a target="_blank" class="fl-x-c pl-0 d-none"
                   href="https://www.google.com/maps/place/Xeoscript+Technologies/@10.5306645,76.2554481,17z/data=!3m1!4b1!4m5!3m4!1s0x3ba7ef85a79a8d9f:0x863b98ff3045461e!8m2!3d10.5306592!4d76.2576368">
                    <img src="@/assets/images/location.png" class="address-icon">
                    <p> <span
                        class="d-md-inline-block d-none">Mannuthy,</span> Thrissur, Kerala</p>
                </a>
                <a target="_blank" href="mailto:info@xeoscript.com" class="fl-x-c pl-0">
                    <img src="@/assets/images/mail.png" class="address-icon">
                    <p>info@xeoscript.com</p>
                </a>
                <a href="tel:+91 7994000666" class="fl-x-c">
                    <img src="@/assets/images/call.png" class="address-icon">
                    <p>+91 7994000666</p>
                </a>

            </div>
            <div class="social-media ">
                <a target="_blank" href="https://www.facebook.com/xeoscript/">
                    <img src="@/assets/images/header-socialmedia/fb.png" class="social-icon">
                </a>
                <!--                <a class="d-none" target="_blank" href="https://twitter.com/xeoscript">-->
                <!--                    <img src="@/assets/images/header-socialmedia/twitt.png" class="social-icon">-->
                <!--                </a>-->
                <a target="_blank" href="https://www.linkedin.com/company/xeoscript-technologies/">
                    <img src="@/assets/images/header-socialmedia/linkedin.png" class="social-icon">
                </a>
                <a target="_blank" href=" https://www.instagram.com/xeoscript/">
                    <img src="@/assets/images/header-socialmedia/insta.png" class="social-icon">
                </a>
                <!--                <a target="_blank" href="">-->
                <!--                    <img src="@/assets/images/header-socialmedia/utube.png" class="social-icon">-->
                <!--                </a>-->
                <!--                <a class="d-none" target="_blank" href="">-->
                <!--                    <img src="@/assets/images/header-socialmedia/beh.png" class="social-icon">-->
                <!--                </a>-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SubNav',
    props: {}
};
</script>

<style scoped lang="scss">
.sub-nav {
    margin-top: 60px;
    padding: 1rem 0;
    background-color: #222222;
    border-bottom: 3px solid var(--color-primary);
}

.contact-info,
.social-media {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.social-media a {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contact-info img {
    width: 15px;
    /*height: 15px;*/
}

.contact-info p {
    color: #fff;
    margin: 0;
    padding-left: 0.5em;
}

.social-media {
    display: none;
}

.social-media .social-icon {
    height: 15px;
    padding-left: 1em;
}

@media all and (min-width: 992px) {

    .sub-nav {
        margin-top: 60px;
        padding: 0.6em 0;
        font-size: 0.9rem;
    }

    .social-media {
        display: flex;
        align-items: center;
    }

    .contact-info div:not(:first-child), .contact-info a {
        padding-left: 1.8em;
    }

    .sub-nav-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@media all and (min-width: 1025px) {

    .sub-nav {
        margin-top: 80px;
    }
}

@media all and (max-width: 1150px) {
    /*.sub-nav {*/
    /*    padding-left: 1em;*/
    /*    padding-right: 1em;*/
    /*}*/
    .sub-nav {
        flex-direction: column;
        align-items: center;
        padding: 0.1rem 0;
    }

    .social-media {
        margin-top: 1em;
    }
}

@media all and (max-width: 520px) {
    .contact-info {
        font-size: 0.8rem;
    }

    .contact-info img {
        width: 12px;
    }

    .sub-nav-box {
        padding: 0.5em;
    }
}

@media all and (max-width: 400px) {
    .contact-info {
        font-size: 0.7rem;
    }

    .contact-info img {
        width: 11px;
    }
}

@media all and (max-width: 360px) {
    .contact-info {
        font-size: 0.6rem;
    }

    .contact-info img {
        width: 10px;
    }
}

@media all and (max-width: 768px) {
    /*.contact-info div {*/
    /*    padding-left: 0.5em;*/
    /*    word-break: break-word;*/
    /*}*/
}
</style>
