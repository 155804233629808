<template>
    <div class="footer-container">
        <div class="container py-4">
            <div class="row footer-component">
                <div class="footer-1 col-6 col-lg">
                    <h5>Navigations</h5>
                    <ul>
                        <li>
                            <router-link to="/">
                                Home
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/about/">
                                About Us
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/web-app-thrissur-kerala/">
                                Services
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/e-commerce-thrissur-kerala/">
                                Products
                            </router-link>
                        </li>
                        <li class="d-none">
                            <router-link to="/our-works/">
                                Our Works
                            </router-link>
                        </li>
                        <li class="d-none">
                            <router-link to="">
                                Career
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/contact-us/">
                                Contact
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="footer-2 col-6 col-lg">
                    <h5>Services</h5>
                    <ul>
                        <li>
                            <router-link to="/software-development-thrissur-kerala/">
                                Software Development
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/web-app-thrissur-kerala/">
                                Web Application
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/mobile-application-development-thrissur-kerala/">
                                Mobile App Development
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/web-design-thrissur-kerala/">
                                Website Design
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/digital-marketing-thrissur-kerala/">
                                Digital Marketing
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/e-commerce-thrissur-kerala/">
                                E commerce Development
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="footer-3 col-12 col-lg mt-5 mt-lg-0">
                    <h5>Contact</h5>
                    <ul>
                        <li>Xeoscript Technologies</li>
                        <li>First Floor, Kalarikkal Building,</li>
                        <li> Mannuthy ByPass, Mannuthy P O</li>
                        <li> Thrissur, Kerala, India</li>
                        <li>PIN: 680 651</li>
                        <ul class="fl-x-c social-media mt-2">
                            <a target="_blank" href="https://www.facebook.com/xeoscript/">
                                <img src="@/assets/images/header-socialmedia/fb.png" class="social-icon">
                            </a>
                            <!--                        <a target="_blank" href="https://twitter.com/xeoscript">-->
                            <!--                            <img src="@/assets/images/header-socialmedia/twitt.png" class="social-icon">-->
                            <!--                        </a>-->
                            <a target="_blank" href="https://www.linkedin.com/company/xeoscript-technologies/">
                                <img src="@/assets/images/header-socialmedia/linkedin.png" class="social-icon">
                            </a>
                            <a target="_blank" href=" https://www.instagram.com/xeoscript/">
                                <img src="@/assets/images/header-socialmedia/insta.png" class="social-icon">
                            </a>
                            <!--                        <a target="_blank" href="">-->
                            <!--                            <img src="@/assets/images/header-socialmedia/utube.png" class="social-icon">-->
                            <!--                        </a>-->
                            <!--                        <a target="_blank" href="">-->
                            <!--                            <img src="@/assets/images/header-socialmedia/beh.png" class="social-icon">-->
                            <!--                        </a>-->
                        </ul>
                    </ul>
                </div>
                <div class="footer-4 col-12 col-lg mt-5 mt-lg-0">
                    <h5>Newsletter</h5>
                    <div class="mt-3">
                        <transition name="fade" mode="out-in">
                            <div class="" v-if="state === 'S'" key="'S">
                                <div class="">
                                    <div class="mb-2">
                                        <i class="fa fa-check-square-o  fa-2x" aria-hidden="true"></i>
                                    </div>
                                    <div class="">
                                        Thank you
                                        <br>
                                        <p>You have successfully subscribed to our newsletter</p>
                                    </div>
                                </div>
                            </div>

                            <div class="" v-else-if="state === 'F'" key="'F">
                                <div class="">
                                    <div class="mb-2">
                                        <i class="fa fa-window-close fa-2x" aria-hidden="true"></i>
                                    </div>
                                    <div class="">
                                        OOPS!
                                        <br>
                                        <p>Something went wrong</p>
                                    </div>
                                </div>
                            </div>
                            <div class="alert alert-info" v-else-if="state === 'L'" key="'L">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="">
                                        <p class="c-font-19 mb-4 proxim-light">Please wait while communicate with the
                                            server</p>
                                    </div>
                                </div>
                            </div>
                            <s-form class="row text-white" v-else role="form" @submit="onSubmit" autocomplete="off">
                                <div class="col-12">
                                    <validated-input type="email" name="Email" color="text-white" v-model="email"
                                                     border-radius="0"
                                                     placeholder="" :rules="rules.email_id"/>
                                </div>
                                <div class="col">
                                    <p class="mt-2">Enter your email ID above to subscribe to our newsletter</p>
                                    <button class="lego-btn xeo-btn-3 round-1 mt-2 px-5 px-xl-4 fs--1">Subscribe Now !
                                    </button>
                                </div>
                            </s-form>
                        </transition>

                    </div>

                    <div class="fl-x-c social-media-mobile mt-4">
                        <a target="_blank" href="https://www.facebook.com/xeoscript/">
                            <img src="@/assets/images/header-socialmedia/fb.png" class="social-icon">
                        </a>
                        <!--                        <a target="_blank" href="https://twitter.com/xeoscript">-->
                        <!--                            <img src="@/assets/images/header-socialmedia/twitt.png" class="social-icon">-->
                        <!--                        </a>-->
                        <a target="_blank" href="https://www.linkedin.com/company/xeoscript-technologies/">
                            <img src="@/assets/images/header-socialmedia/linkedin.png" class="social-icon">
                        </a>
                        <a target="_blank" href=" https://www.instagram.com/xeoscript/">
                            <img src="@/assets/images/header-socialmedia/insta.png" class="social-icon">
                        </a>
                        <a target="_blank" href="">
                            <img src="@/assets/images/header-socialmedia/utube.png" class="social-icon">
                        </a>
                        <!--                        <a target="_blank" href="">-->
                        <!--                            <img src="@/assets/images/header-socialmedia/beh.png" class="social-icon">-->
                        <!--                        </a>-->
                    </div>
                </div>
            </div>

        </div>
        <!--        <a @click="scrollTop" class="scroll-to-top" v-show="visible"  href="#top" id="scroll-top">-->
        <!--            <img src="@/assets/images/double-arrow.png" alt="">-->
        <!--        </a>-->
        <div class="container-fluid">
            <div class="row footer-component1">
                <p> Xeoscript Technologies {{ new Date().getFullYear() }} All Rights Reserved</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Footer',
    props: {},
    data () {
        return {
            state: 'D',
            email: '',
            rules: {
                email_id: {
                    required: true
                }
            },
            visible: false
        };
    },
    methods: {
        scrollTop: function () {
            this.intervalId = setInterval(() => {
                if (window.pageYOffset === 0) {
                    clearInterval(this.intervalId);
                }
                window.scroll(0, window.pageYOffset - 50);
            }, 10);
        },
        scrollListener: function (e) {
            this.visible = window.scrollY > 150;
        },
        onSubmit () {
            const bodyFormData = new FormData();
            bodyFormData.append('email', this.email);

            const that = this;
            this.state = 'L';

            axios({
                method: 'post',
                url: 'https://xeoscript.com/newsletter.php',
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(function (response) {
                that.onSubmitSuccess(response);
            }).catch(function (response) {
                that.onSubmitFailed(response);
            });
        },

        onSubmitSuccess () {
            this.state = 'S';
        },

        onSubmitFailed () {
            this.state = 'F';
        },
        mounted: function () {
            window.addEventListener('scroll', this.scrollListener);
        },
        beforeDestroy: function () {
            window.removeEventListener('scroll', this.scrollListener);
        }
    }
};
</script>

<style scoped>

.footer-container {
    background-color: #222222;
    color: rgb(255, 255, 255);
    position: relative;
}

.footer-component {
    /*padding: 3rem 0;*/
    /*font-size: 13px;*/
    /*justify-content: space-between;*/
}

.footer-component1 {
    padding: 1em 0 0 0em;
    color: rgb(184, 184, 184);
    font-size: 13px;
    justify-content: center;
    border-top: 1px solid #464646;
}

.footer-component ul {
    padding: 0;
    list-style-type: none;
}

.footer-component ul li {
    padding-top: 0.5em;
}

.footer-component h4 {
    font-weight: 600;
}

/*.footer-4 input {*/
/*    background-color: inherit;*/
/*    color: #fff;*/
/*}*/

/*.footer-4 input:focus {*/
/*    color: #fff;*/
/*    border-color: #fff;*/
/*}*/

.footer-4 p {
    width: 70%;
    font-size: 11px;
}

/*.footer-4 button {*/
/*    width: 50%;*/
/*}*/

.social-media .social-icon,
.social-media-mobile .social-icon {

    height: 15px;
}

.social-media a:not(:first-child),
.social-media-mobile a:not(:first-child) {
    padding-left: 1em;
}

.scroll-to-top {
    position: fixed;
    right: 1.5rem;
    bottom: 1rem;
    background: #01b7f4d1;
    padding: 5px;
    margin: 0;
    height: 45px;
    width: 45px;
    border-radius: 50%;
}

.scroll-to-top img {
    width: 100%;
}

.social-media-mobile {
    display: none;
}

@media all and (max-width: 992px) {
    .footer-component {
        /*padding-left: 1em;*/
        /*padding-right: 1em;*/
        /*flex-direction: column;*/

    }

    .footer-4 p {
        width: 100%;
    }

    .social-media {
        justify-content: center;
    }

    /*.footer-2,*/
    /*.footer-3,*/
    /*.footer-4 {*/
    /*    margin-top: 1em;*/
    /*}*/
    .scroll-to-top, .social-media {
        display: none;
    }

    .social-media-mobile {
        display: flex;
        justify-content: center;
    }
}
</style>
